import isEmpty from 'lodash.isempty'
import get from 'lodash.get'
import { getDate, isValidDate } from 'use/Helper'

const filterEntries = (rows, columns = [], searchTerm) => {
    if (isEmpty(searchTerm) || isEmpty(rows) || isEmpty(columns)) {
        return rows
    }
    try {
        const regex = new RegExp(searchTerm, 'i')
        return (rows || []).filter((row) =>
            columns.some((field) => {
                const value = get(row, field.value)
                if (field.type === 'date') {
                    const validDate = isValidDate(value)
                    return validDate ? regex.test(getDate(value)) : false
                }
                return regex.test(value)
            })
        )
    } catch (error) {
        console.error(error)
    }
    return rows
}

export default filterEntries
