import React from 'react'
import 'react-phone-input-2/lib/style.css'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import parseISO from 'date-fns/parseISO'
import Wrapper from 'components/Wrapper'
import { languageData, genderData, relationData, raceOptions } from 'use/Const'
import config from 'use/Config'
import './MyProfile.scss'
import popupNotification from 'components/Notification/popupNotification'
import Loader from 'components/Loader/Loader'
import FormGroupHeader from 'components/FormGroupHeader/FormGroupHeader'
import TextField from 'components/Form/TextField'
import FormSubmitButtons from 'components/FormSubmitButtons'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import WarningIcon from '@mui/icons-material/Warning'
import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import MrcTooltip from 'components/MrcTooltip'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import mrcFetch from 'util/authFetch'
import { LoginService } from 'services/login.service'
import { Checkbox } from '@mui/material'
// import { Checkbox, Stack } from '@mui/material'
// import { Divider, Typography } from 'antd'
// import { ToolTipIcon } from 'assets/svg/tooltipIcon'
import { NewToolTipIcon } from '../../../components/NewToolTipIcon'
// import ConnectPatientCerner from './ShowPopupModal'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root, & .MuiDialogContent-root': {
        paddingLeft: '120px',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const WarningDialogIcon = styled(WarningIcon)(({ theme }) => ({
    '&': {
        position: 'absolute',
        left: '25px',
        fontSize: '60px',
        top: '30%',
    },
}))

const InsuranceRow = styled(Box)(({ theme }) => ({
    position: 'relative',

    '&:first-of-type': {
        backgroundColor: 'red',
    },
}))

const InsuranceBar = styled(Box)(({ theme, primary }) => ({
    position: 'absolute',
    backgroundColor: primary ? theme.palette.anon.vividCerulean : theme.palette.anon.medSpringGreen,
    width: 3,
    height: '90%',
    padding: 0,
    top: 0,
    bottom: 0,
    margin: 'auto 0',
    left: -10,

    [theme.breakpoints.down('md')]: {
        width: 4,
        height: '97%',
        left: -3,
    },

    [theme.breakpoints.down('sm')]: {
        height: '96%',
    },
}))

class MyProfile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            relationship: '',
            birthDate: null,
            gender: '',
            preferredLanguage: '',
            shareRecord: '',
            saving: false,
            // State for if delete account popup div is hidden or not
            hidePop: true,
            shouldRenderErrors: false,
            isFormValid: false,
            showSnackbar: false,
            textFieldInputs: [
                {
                    stateKey: 'firstName',
                    label: 'First name',
                    isRequired: true,
                },
                {
                    stateKey: 'lastName',
                    label: 'Last name',
                    isRequired: true,
                },
                {
                    stateKey: 'email',
                    label: 'Email',
                    isRequired: true,
                    extraValidation: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                },
                {
                    stateKey: 'relationship',
                    label: 'Relation',
                    isRequired: true,
                    childrenItems: relationData.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    )),
                },
                {
                    stateKey: 'birthDate',
                    label: 'DOB',
                    inputProps: { max: new Date().toISOString().split('T')[0] },
                    isDate: true,
                    isRequired: true,
                },
                {
                    stateKey: 'gender',
                    label: 'Gender',
                    childrenItems: genderData.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    )),
                },
                {
                    stateKey: 'preferredLanguage',
                    label: 'Preferred Language',
                    isRequired: true,
                    childrenItems: languageData.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    )),
                },
                {
                    stateKey: 'address',
                    label: 'Address',
                },
                {
                    stateKey: 'city',
                    label: 'City',
                },
                {
                    stateKey: 'state',
                    label: 'State',
                },
                {
                    stateKey: 'zip',
                    label: 'Zip code',
                    isRequired: true,
                },
                // {
                //     stateKey: 'ssn',
                //     label: 'SSN',
                // },
                {
                    stateKey: 'contact',
                    label: 'Cell phone',
                },
                {
                    stateKey: 'homeNumber',
                    label: 'Home phone',
                },
                {
                    stateKey: 'race',
                    label: 'Race',
                    childrenItems: raceOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    )),
                },
            ],
            isVeteranToken: localStorage.getItem('EHRconnected') === 'Veteran Valor',
        }
    }
    componentDidMount() {
        this.getProfileData()
    }

    // Retrieves profile data from local storage
    getProfileData = async (loader = true) => {
        const email = localStorage.getItem('email')
        if (!email) return
        if (loader) this.setState({ saving: true })
        try {
            const { data: loginData } = await LoginService.getUser(email)
            if (loginData) {
                if (loader) this.setState({ saving: false })
                const { birthDate } = loginData.profile
                let parsedBirthDate = undefined
                if (birthDate) {
                    try {
                        parsedBirthDate = parseISO(birthDate)
                    } catch (err) {
                        console.error(err)
                    }
                }
                this.setState({
                    ...loginData.profile,
                    email: loginData.email,
                    emergencyContact: loginData.profileEmergencyContact,
                    primaryInsuranceInformation: {
                        companyName: loginData.profileInsuranceInformation?.primaryCompanyName,
                        groupNumber: loginData.profileInsuranceInformation?.primaryGroupNumber,
                        idNumber: loginData.profileInsuranceInformation?.primaryIdNumber,
                        companyAddress: loginData.profileInsuranceInformation?.primaryCompanyAddress,
                        employer: loginData.profileInsuranceInformation?.primaryEmployer,
                        policyHolder: loginData.profileInsuranceInformation?.primaryPolicyHolder,
                    },
                    secondaryInsuranceInformation: {
                        companyName: loginData.profileInsuranceInformation?.secondaryCompanyName,
                        groupNumber: loginData.profileInsuranceInformation?.secondaryGroupNumber,
                        idNumber: loginData.profileInsuranceInformation?.secondaryIdNumber,
                        companyAddress: loginData.profileInsuranceInformation?.secondaryCompanyAddress,
                        employer: loginData.profileInsuranceInformation?.secondaryEmployer,
                        policyHolder: loginData.profileInsuranceInformation?.secondaryPolicyHolder,
                    },
                    birthDate: parsedBirthDate,
                })
            }
        } catch (error) {
            if (loader) this.setState({ saving: false })
            console.log('error', error)
            localStorage.removeItem('token')
            localStorage.removeItem('isWebsite')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('email')
            localStorage.clear()
            window.location.href = 'login'
            //             alert(`Error retrieving user information`)
        }
    }

    // Ensures all necessary user info has been entered for submission
    validateForm = () => {
        const { textFieldInputs, emergencyContact } = this.state
        const isFormValid = textFieldInputs.every(({ stateKey, isRequired, extraValidation }) => {
            if (extraValidation) {
                const meetExtraValidation = extraValidation?.test(this.state[stateKey])

                if (!meetExtraValidation) return false
            }

            if (!isRequired) return true

            return !!this.state[stateKey]
        })
        if (emergencyContact && emergencyContact?.email?.length) {
            const meetExtraValidation = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emergencyContact?.email)
            if (!meetExtraValidation) return false
        }

        if (this.state.isFormValid !== isFormValid || !this.state.shouldRenderErrors) {
            this.setState({ shouldRenderErrors: true, isFormValid })
        }

        return isFormValid
    }

    // Ensures all necessary user info has been entered for submission
    handleOnCancel = () => {
        this.getProfileData()
    }

    handleDeleteAccount = async () => {
        try {
            await LoginService.deleteAccount()
            popupNotification(
                'We have been notified! Your request to delete your account will be processed within 10 days.',
                true
            )
            this.setState({ hidePop: true })
        } catch (error) {
            popupNotification('Something went wrong.', false)
            this.setState({ hidePop: true })
        }
    }

    // Updates patient information in matchrite database
    handleSubmitPress = () => {
        const valid = this.validateForm()

        if (!valid) {
            this.setState({ showSnackbar: true })

            return
        }

        this.setState({ saving: true })

        const { shouldRenderErrors, isFormValid, showSnackbar, textFieldInputs, ...formData } = this.state

        formData.cellPhone = formData.contact
        formData.shareRecord = formData.shareRecord === 'Yes' ? true : false
        // formData.ssn=formData.ssn ||''

        if (formData.emergencyContact) formData.emergencyContact.relation = formData.emergencyContact?.relationship
        var data = JSON.stringify(formData)
        mrcFetch(`${config.mrc.baseApiUrl}/Login/UpdateProfile?profileId=${this.state.id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },

            body: data,
        })
            .then((response) => response.text())
            .then(async (responseText) => {
                this.setState({ saving: false })
                popupNotification(responseText.replace(/"/g, ''), true)
                this.getProfileData(false)
            })
            .catch((error) => {
                this.setState({ saving: false })
                popupNotification('Error occured', false)
            })
    }

    render() {
        const { textFieldInputs } = this.state
        if (this.state.saving) {
            return <Loader pageName="myprofile" />
        }

        return (
            <div className="container-right">
                <BootstrapDialog
                    fullWidth
                    maxWidth="sm"
                    open={!this.state.hidePop}
                    onClose={() => this.setState({ hidePop: true })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Do you want to delete your account?</DialogTitle>
                    <DialogContent>
                        <WarningDialogIcon />
                        <DialogContentText id="alert-dialog-description">
                            Your account will be deleted entirely upon confirmation. Make sure to save any important
                            data as this process is irreversible.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ hidePop: true })}>No, keep.</Button>
                        <Button
                            autoFocus
                            variant="contained"
                            color="error"
                            onClick={() => {
                                this.handleDeleteAccount()
                            }}
                        >
                            Yes, delete.
                        </Button>
                    </DialogActions>
                </BootstrapDialog>

                <div className="white-container myprofile padding-28">
                    <FormGroupHeader>Patient Information</FormGroupHeader>

                    <br />
                    <br />

                    {/* Input fields for patient personal information */}
                    <div className="row">
                        {textFieldInputs.map(
                            ({ isDate, label, stateKey, inputProps, childrenItems, isRequired, extraValidation }) => {
                                const { shouldRenderErrors } = this.state
                                const meetExtraValidation =
                                    extraValidation && typeof extraValidation === 'string'
                                        ? extraValidation?.test(this.state[stateKey])
                                        : true
                                const hasError =
                                    (shouldRenderErrors && !this.state[stateKey] && isRequired) ||
                                    (shouldRenderErrors && !meetExtraValidation)

                                return (
                                    <Wrapper textCenter={false}>
                                        {isDate ? (
                                            <DatePicker
                                                required={isRequired}
                                                label={label}
                                                inputFormat="MM/dd/yyyy"
                                                value={this.state[stateKey]}
                                                onChange={(d) => this.setState({ [stateKey]: d })}
                                                renderInput={(params) => (
                                                    <TextField required={isRequired} {...params} />
                                                )}
                                                inputProps={inputProps}
                                            />
                                        ) : (
                                            <TextField
                                                error={hasError}
                                                select={!!childrenItems}
                                                required={isRequired}
                                                label={label}
                                                value={this.state[stateKey]}
                                                onChange={(e) => this.setState({ [stateKey]: e.target.value })}
                                            >
                                                {childrenItems}
                                            </TextField>
                                        )}
                                        <div>
                                            {hasError && (
                                                <MuiAlert
                                                    variant="standard"
                                                    severity="error"
                                                    sx={{ background: 'none', padding: 0, color: 'red' }}
                                                >
                                                    This field is required
                                                </MuiAlert>
                                            )}
                                        </div>
                                    </Wrapper>
                                )
                            }
                        )}
                    </div>

                    <br />

                    {/* Input fields for patient emergency contact information */}
                    <FormGroupHeader>Who should we notify in case of emergency?</FormGroupHeader>

                    <br />
                    <br />

                    <div className="row">
                        <Wrapper>
                            <TextField
                                label="Name"
                                value={this.state.emergencyContact?.name}
                                onChange={(e) =>
                                    this.setState({
                                        emergencyContact: { ...this.state.emergencyContact, name: e.target.value },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Phone number"
                                value={this.state.emergencyContact?.phoneNumber}
                                onChange={(e) =>
                                    this.setState({
                                        emergencyContact: {
                                            ...this.state.emergencyContact,
                                            phoneNumber: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Email"
                                placeholder="Email"
                                inputProps={{
                                    type: 'email',
                                }}
                                defaultValue={this.state.emergencyContact?.email}
                                onChange={(e) => {
                                    this.setState({
                                        emergencyContact: {
                                            ...this.state.emergencyContact,
                                            email: e.target.value,
                                        },
                                    })
                                }}
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Address"
                                value={this.state.emergencyContact?.address}
                                onChange={(e) =>
                                    this.setState({
                                        emergencyContact: { ...this.state.emergencyContact, address: e.target.value },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Relationship"
                                value={this.state.emergencyContact?.relationship}
                                onChange={(e) =>
                                    this.setState({
                                        emergencyContact: {
                                            ...this.state.emergencyContact,
                                            relationship: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                    </div>

                    <br />
                    {/* Input fields for patient emergency contact information */}
                    {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <FormGroupHeader>Insurance information</FormGroupHeader>
                        <Stack direction="row" spacing="28px">
                            <Box>
                                <Stack direction="row" alignItems="center" spacing="6px">
                                    <Box sx={{ width: '10px', height: '10px', background: '#0099ff' }} />
                                    <Typography className="fontInsurance">Primary</Typography>
                                </Stack>
                                <Divider style={{ marginTop: '5px', border: '1px solid #CCCCCC' }} />
                            </Box>
                            <Box>
                                <Stack direction="row" alignItems="center" spacing="6px">
                                    <Box sx={{ width: '10px', height: '10px', background: '#00FF99' }} />
                                    <Typography className="fontInsurance">Secondary</Typography>
                                </Stack>
                                <Divider style={{ marginTop: '5px', border: '1px solid #CCCCCC' }} />
                            </Box>
                        </Stack>
                    </Stack>

                    <br />

                    <InsuranceRow className="row">
                        <InsuranceBar primary />
                        <Wrapper>
                            <TextField
                                label="Company name"
                                value={this.state.primaryInsuranceInformation?.companyName}
                                onChange={(e) =>
                                    this.setState({
                                        primaryInsuranceInformation: {
                                            ...this.state.primaryInsuranceInformation,
                                            companyName: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Group number"
                                value={this.state.primaryInsuranceInformation?.groupNumber}
                                onChange={(e) =>
                                    this.setState({
                                        primaryInsuranceInformation: {
                                            ...this.state.primaryInsuranceInformation,
                                            groupNumber: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="ID number"
                                value={this.state.primaryInsuranceInformation?.idNumber}
                                onChange={(e) =>
                                    this.setState({
                                        primaryInsuranceInformation: {
                                            ...this.state.primaryInsuranceInformation,
                                            idNumber: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Company address"
                                value={this.state.primaryInsuranceInformation?.companyAddress}
                                onChange={(e) =>
                                    this.setState({
                                        primaryInsuranceInformation: {
                                            ...this.state.primaryInsuranceInformation,
                                            companyAddress: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Employer"
                                value={this.state.primaryInsuranceInformation?.employer}
                                onChange={(e) =>
                                    this.setState({
                                        primaryInsuranceInformation: {
                                            ...this.state.primaryInsuranceInformation,
                                            employer: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Policy holder"
                                value={this.state.primaryInsuranceInformation?.policyHolder}
                                onChange={(e) =>
                                    this.setState({
                                        primaryInsuranceInformation: {
                                            ...this.state.primaryInsuranceInformation,
                                            policyHolder: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                    </InsuranceRow>
                    <br />
                    <InsuranceRow className="row">
                        <InsuranceBar secondary />
                        <Wrapper>
                            <TextField
                                label="Company name"
                                value={this.state.secondaryInsuranceInformation?.companyName}
                                onChange={(e) =>
                                    this.setState({
                                        secondaryInsuranceInformation: {
                                            ...this.state.secondaryInsuranceInformation,
                                            companyName: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Group number"
                                value={this.state.secondaryInsuranceInformation?.groupNumber}
                                onChange={(e) =>
                                    this.setState({
                                        secondaryInsuranceInformation: {
                                            ...this.state.secondaryInsuranceInformation,
                                            groupNumber: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="ID number"
                                value={this.state.secondaryInsuranceInformation?.idNumber}
                                onChange={(e) =>
                                    this.setState({
                                        secondaryInsuranceInformation: {
                                            ...this.state.secondaryInsuranceInformation,
                                            idNumber: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Company address"
                                value={this.state.secondaryInsuranceInformation?.companyAddress}
                                onChange={(e) =>
                                    this.setState({
                                        secondaryInsuranceInformation: {
                                            ...this.state.secondaryInsuranceInformation,
                                            companyAddress: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Employer"
                                value={this.state.secondaryInsuranceInformation?.employer}
                                onChange={(e) =>
                                    this.setState({
                                        secondaryInsuranceInformation: {
                                            ...this.state.secondaryInsuranceInformation,
                                            employer: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                        <Wrapper>
                            <TextField
                                label="Policy holder"
                                value={this.state.secondaryInsuranceInformation?.policyHolder}
                                onChange={(e) =>
                                    this.setState({
                                        secondaryInsuranceInformation: {
                                            ...this.state.secondaryInsuranceInformation,
                                            policyHolder: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Wrapper>
                    </InsuranceRow> */}
                    {/* <br /> */}

                    {/* Input fields for patient emergency contact information */}
                    <FormControlLabel
                        id="ShareRecord"
                        control={
                            <Checkbox
                                color="primary"
                                // icon={this.state.shareRecord ? <CheckedIcon /> : <CheckboxIcon />}
                                onChange={(e) => this.setState({ shareRecord: e.target.checked })}
                                checked={this.state.shareRecord}
                            />
                        }
                        label="Would you like to share your health record to support clinical trials and medical research?"
                        // label=" Would you like to Share your record for research?"
                    />
                    {/* <FormGroupHeader> */}

                    {/* <MrcTooltip text=" Disclaimer: Share your healthcare data to help with research We will not share your personal information such as name, DOB, address, race, and sexual orientation."> */}
                    <MrcTooltip
                        text={
                            <span>
                                {/* 'Enabling this selection will share your personally identifiable health record with subscribing Research Organizations.',
                                    <br />,
                                    'These organizations will have read-only access to medical records for research purposes.',
                                    <br />,
                                    'Disable research sharing by de-selecting this option.', */}
                                {[
                                    'By enabling this option, you can share your health records with trusted research organizations and clinical trials, granting them read-only access for valuable research purposes.',
                                    <br />,
                                    'Your participation supports advancements in medical science, contributing to new treatments and better care for all.',
                                ]}
                            </span>
                        }
                    >
                        {({ onOpen }) => (
                            <IconButton color="primary" aria-label="Info" onClick={onOpen}>
                                {/* <ToolTipIcon /> */}
                                <NewToolTipIcon top={0} />
                            </IconButton>
                        )}
                    </MrcTooltip>
                    {/* </FormGroupHeader> */}

                    <br />

                    {/* Submit user input information */}
                    {/* <div className="button-sec">
                        <MrcButton
                            disabled={!this.validateForm()}
                            onClick={}
                            startIcon={<SaveOutlinedIcon />}
                            className={!this.validateForm() ? 'disabled' : ''}
                        >
                            Save
                        </MrcButton>
                    </div> */}
                    <br />
                    <br />
                    <Snackbar
                        open={this.state.showSnackbar}
                        autoHideDuration={6000}
                        onClose={() => this.setState({ showSnackbar: false })}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert
                            onClose={() => this.setState({ showSnackbar: false })}
                            severity="error"
                            sx={{ width: '100%' }}
                        >
                            Please fill in all the details
                        </Alert>
                    </Snackbar>

                    <FormSubmitButtons
                        submitTitle="Save Info"
                        onSubmit={this.handleSubmitPress}
                        onCancel={this.handleOnCancel}
                        onDelete={() => this.setState({ hidePop: false })}
                    />
                </div>
            </div>
        )
    }
}

export default MyProfile
