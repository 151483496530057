import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import { useSearchField } from 'hoc/SearchFieldProvider'
import { Autocomplete, Stack, Select, TextField, useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react'

function SearchField({ pathname }) {
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
    const {
        searchTerm,
        searchColumns,
        filterByColumn,
        setSearchTerm,
        setFilterByColumn,
        dependents,
        setSelectedDependent,
    } = useSearchField()

    const handleOnChangeSelect = (event) => {
        setFilterByColumn(searchColumns.find((c) => c.value === event.target.value))
    }

    const handleOnSearch = (e) => {
        if (!e) return
        e.preventDefault()
        setSearchTerm(e.target.value)
    }

    return (
        <Stack direction="row" alignItems="center" spacing="5px" sx={{ overflowX: 'hidden', paddingTop: '5px' }}>
            <Select value={filterByColumn?.value || 'all'} onChange={handleOnChangeSelect}>
                <MenuItem value="all">All</MenuItem>
                {searchColumns && searchColumns.map(({ value, label }) => <MenuItem value={value}>{label}</MenuItem>)}
            </Select>
            <TextField
                placeholder="Search…"
                sx={{ width: !isLargeScreen ? '215px' : '350px', borderRadius: '12px' }}
                value={searchTerm}
                onChange={handleOnSearch}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Autocomplete
                sx={{ width: '250px', borderRadius: '12px' }}
                options={dependents}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option) => option.id}
                onChange={(_, value) => {
                    setSelectedDependent(value)
                }}
                error={true}
                renderInput={(params) => <TextField {...params} label={`Select Dependent`} variant="outlined" />}
            />
        </Stack>
    )
}

export default SearchField
