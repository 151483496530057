import React from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

import { getDate, isValidDate } from 'use/Helper'
import Loader from 'components/Loader/Loader'
import { ExplanationOfBenefitAPI } from 'services/Services'
// Popup that displays details about a selected insurance claim
import AlertDialog from 'components/AlertDialog'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'
import './YourClaims.scss'
import { IconButton } from '@mui/material'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'

class YourClaimsImpl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            open: false,
            selected: {},
            loading: true,
        }
    }

    componentDidMount() {
        this.explanationOfBenefit()
        const { setSearchColumns } = this.props
        setSearchColumns([
            {
                value: 'claims',
                label: 'Claims',
            },
            {
                value: 'adjudicationamount',
                label: 'Amount',
            },
            {
                value: 'insurance',
                label: 'Insurance',
            },
            {
                value: 'status',
                label: 'Status',
            },
            {
                value: 'organization',
                label: 'Organization',
            },
            {
                value: 'service',
                label: 'Service',
            },
        ])
    }

    // Returns EOBs from matchrite database
    explanationOfBenefit = async () => {
        let profileid = localStorage.getItem('profileid')
        const data = await ExplanationOfBenefitAPI(profileid)

        if (data) {
            if (data.message === 'No Data Found') {
                await this.setState({ value: [], loading: false })
            } else {
                await this.setState({
                    value: data.explanationOfBenefitData,
                    loading: false,
                })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    // Changes component states (not actually used)
    handleChangeInput = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }

    // Download claims in csv file (not actually used)
    exportClaims = () => {
        if (this.state.value.length === 0) {
            return
        }

        const con = []

        let csvContent = 'data:text/csvcharset=utf-8,'

        Object.keys(this.state.value).forEach((data) => {
            const val = this.state.value[data]
            con.push(val)
        })

        const title = `Last Updated, Status, Patient, Organization, Facility, Care Team, Insurance, Service, Serviced Date, Quantity, Adjudication Amount, Adjudication Display`
        csvContent += title + '\r\n'

        con.forEach(function (rowArray) {
            const row =
                '"' +
                rowArray.lastUpdated +
                '","' +
                rowArray.status +
                '","' +
                rowArray.patient +
                '","' +
                rowArray.organization +
                '","' +
                rowArray.facility +
                '","' +
                rowArray.careTeam +
                '","' +
                rowArray.insurance +
                '","' +
                rowArray.service +
                '","' +
                rowArray.servicedDate +
                '","' +
                rowArray.quantity +
                '","' +
                rowArray.adjudicationamount +
                '","' +
                rowArray.adjudicationDisplay +
                '"'
            csvContent += row + '\r\n'
        })

        csvContent = csvContent.replace(/#/g, '')
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)

        const d = new Date()
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
        const _d = `${mo}-${da}-${ye}`

        link.setAttribute('download', `Exported on ${_d}.csv`)

        document.body.appendChild(link)
        link.click()
    }

    getFilteredEntries = () => {
        const { value } = this.state
        const { searchTerm, searchColumns, filterByColumn } = this.props
        const columns = filterByColumn?.value === 'all' ? searchColumns : [filterByColumn]
        return filterEntries(value, columns, searchTerm)
    }

    render() {
        if (this.state.loading) {
            return <Loader pageName="yourclaims" />
        }

        // Columns for data grid that will that will list patient records
        const columns = [
            {
                field: 'claims',
                headerName: 'Claims',
                flex: 0.3,
                sortable: false,
                disablecolumnmenu: true,
                renderCell: (params) => <CheckOutlinedIcon className="checkicon" />,
            },
            {
                field: 'subject',
                headerName: 'Date range',
                type: 'date',
                flex: 0.5,
                renderCell: (params) =>
                    isValidDate(params.row.servicedDate) && isValidDate(params.value) ? (
                        <>
                            {isValidDate(params.row.servicedDate) ? getDate(params.row.servicedDate) : '-'} <b> to </b>
                            {isValidDate(params.row.lastUpdated) ? getDate(params.row.lastUpdated) : '-'}
                        </>
                    ) : (
                        <>"-"</>
                    ),
            },
            {
                field: 'adjudicationamount',
                headerName: 'Amount',
                type: 'number',
                flex: 0.3,
            },
            {
                field: 'insurance',
                headerName: 'Insurance',
                flex: 0.5,
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 0.3,
            },
            {
                field: 'organization',
                headerName: 'Organization',
                flex: 0.5,
            },
            {
                field: 'service',
                headerName: 'Service',
                flex: 0.5,
            },
            {
                field: 'more',
                headerName: ' ',
                flex: 0.3,
                sortable: false,
                disablecolumnmenu: true,
                renderCell: (params) => (
                    <div className="icon-group">
                        {/* Icon would probably allow you to share claims information via email, but that functionality isn't set up yet */}
                        {/* <EmailOutlinedIcon className="email-icon table-info-icon" /> */}
                        {/* Icon clicked to display consolidated details on an insurance claim */}
                        <IconButton
                            onClick={() =>
                                this.setState({
                                    selected: params.row,
                                    open: true,
                                })
                            }
                        >
                            <ToolTipIcon />
                        </IconButton>
                    </div>
                ),
            },
        ]

        return (
            <div className="container-right">
                <div className="white-container yourclaims">
                    {/* Data grid that populates cells with patient condition data (limit 5 rows per page)*/}
                    <div className="claims-container">
                        <div style={{ height: 500, width: '100%' }}>
                            <DataGrid
                                columns={columns}
                                disableColumnFilter
                                disableColumnSelector
                                rows={this.getFilteredEntries()}
                                disableSelectionOnClick
                                getRowId={(r) => JSON.stringify(r)}
                            />
                        </div>

                        {Object.keys(this.state.selected).length > 0 && (
                            // Popup that displays details about a selected insurance claim
                            <AlertDialog
                                fullWidth
                                title="Claim details"
                                open={this.state.open}
                                setOpen={(value) => this.setState({ open: value })}
                            >
                                {isValidDate(this.state.selected.lastUpdated) && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Last Updated:</label>{' '}
                                        {getDate(this.state.selected.lastUpdated)}
                                    </div>
                                )}
                                {this.state.selected.status && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Status:</label> {this.state.selected.status || '-'}
                                    </div>
                                )}
                                {this.state.selected.patient && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Patient:</label> {this.state.selected.patient || '-'}
                                    </div>
                                )}
                                {this.state.selected.organization && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Organization:</label>{' '}
                                        {this.state.selected.organization || '-'}
                                    </div>
                                )}
                                {this.state.selected.facility && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Facility:</label>{' '}
                                        {this.state.selected.facility || '-'}
                                    </div>
                                )}
                                {this.state.selected.careTeam && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Care team:</label>{' '}
                                        {this.state.selected.careTeam || '-'}
                                    </div>
                                )}
                                {this.state.selected.insurance && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Insurance:</label>{' '}
                                        {this.state.selected.insurance || '-'}
                                    </div>
                                )}
                                {this.state.selected.service && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Service:</label> {this.state.selected.service || '-'}
                                    </div>
                                )}
                                {this.state.selected.servicedDate && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Serviced date:</label>{' '}
                                        {this.state.selected.servicedDate || '-'}
                                    </div>
                                )}
                                {this.state.selected.quantity && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Quantity:</label>{' '}
                                        {this.state.selected.quantity || '-'}
                                    </div>
                                )}
                                {this.state.selected.adjudicationamount && (
                                    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                        <label className="fw-bold">Adjudication amount:</label>{' '}
                                        {this.state.selected.adjudicationamount || '-'}
                                    </div>
                                )}
                                {this.state.selected.adjudicationDisplay && (
                                    <div>
                                        <label className="fw-bold">Adjudication display:</label>{' '}
                                        {this.state.selected.adjudicationDisplay || '-'}
                                    </div>
                                )}
                            </AlertDialog>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export function YourClaims(props) {
    const { searchTerm, filterByColumn, searchColumns, setSearchColumns } = useSearchField()
    return (
        <YourClaimsImpl
            {...props}
            searchTerm={searchTerm}
            searchColumns={searchColumns}
            filterByColumn={filterByColumn}
            setSearchColumns={setSearchColumns}
        ></YourClaimsImpl>
    )
}

export default YourClaims
