export const getCurrentDate = () => {
    let currentDate = new Date()
    const day = currentDate.getDay()
    const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(currentDate)
    const date = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(currentDate)
    return `${weekday[day]}, ${month} ${date}`
}

export const isValidDate = (dateString) => {
    if (!dateString || typeof dateString !== 'string') return null
    if (dateString?.startsWith('0001-01-01')) {
        return null
    }

    const date = new Date(dateString)

    if (date instanceof Date && !isNaN(date)) return date

    return null
}

function isCurrentDateValid(d) {
    return d instanceof Date && !isNaN(d)
}

export const getDate = (d) => {
    let currentDate = new Date(d)
    if (!currentDate) return '-'
    if (!isCurrentDateValid(currentDate)) return d
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(currentDate)
    const date = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(currentDate)
    // let month = date.getMonth() + 1
    // month = month < 10 ? '0' + month : month

    // let dd = date.getDate()
    // dd = dd < 10 ? '0' + dd : dd

    // return date.getFullYear() + '-' + month + '-' + dd
    return `${month} ${date}, ${currentDate.getFullYear()}`
}
