import React, { useCallback, useState } from 'react'
import Modal from '@mui/material/Modal'
import config from 'use/Config'
import MrcButton from 'components/MrcButton'
import axiosInstance from 'util/axios'
import ReactLoading from 'react-loading'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

const getFilePath = (docid, contentType) => {
    const isHtml =
        contentType === 'text/html' || contentType === 'text/rtf' || contentType === 'application/octet-stream'
            ? ''
            : config.ccda.helperUrl
    return isHtml + process.env.REACT_APP_MRC_BASE_URL + '/MergeEHR/DocumentReferenceBinary?docid=' + docid
}

function parseRtfToHtml(rtfContent) {
    // Create the base container with appropriate styling
    let html = '<div style="font-family: \'Segoe UI\', Arial, sans-serif;">'

    try {
        // Remove font table definition completely
        let content = rtfContent.replace(/\{\\fonttbl\s.*?\}/gs, '')

        // Remove color table definition completely
        content = content.replace(/\{\\colortbl.*?\}/gs, '')

        // Extract colors for reference (doing this before removing the color table)
        const colorMatch = rtfContent.match(/\{\\colortbl;(.*?)\}/s)
        const colorMap = {}

        if (colorMatch && colorMatch[1]) {
            const colorEntries = colorMatch[1].match(/\\red(\d+)\\green(\d+)\\blue(\d+)/g) || []

            colorEntries.forEach((color, index) => {
                const rgb = color.match(/\\red(\d+)\\green(\d+)\\blue(\d+)/)
                if (rgb) {
                    colorMap[index + 1] = `rgb(${rgb[1]}, ${rgb[2]}, ${rgb[3]})`
                }
            })
        }

        // Handle the RTF header more aggressively - remove everything up to the first real content
        content = content.replace(/^.*?\\sectd\\pard\\ltrpar/s, '')

        // Remove all document property settings
        content = content.replace(/\\[a-z]+\d+/g, '')

        // Handle specific formatting - apply proper replacements for styling
        // Match \b with or without a following space
        content = content.replace(/\\b(\s|)/g, '<strong>')
        content = content.replace(/\\b0(\s|)/g, '</strong>')

        // Handle font sizes - convert RTF font size to points
        content = content.replace(/\\fs(\d+)/g, (match, size) => {
            const ptSize = parseInt(size) / 2
            return `<span style="font-size: ${ptSize}pt;">`
        })

        // Handle colors using the extracted color map
        content = content.replace(/\\cf(\d+)/g, (match, colorIndex) => {
            // Default to black if color not found
            const color = colorMap[parseInt(colorIndex)] || 'black'
            return `<span style="color: ${color};">`
        })

        // Handle underline
        content = content.replace(/\\ul\s/g, '<u>')
        content = content.replace(/\\ul0\s/g, '</u>')

        // Handle paragraphs
        content = content.replace(/\\par\s/g, '</span><br>')

        // Remove other control sequences
        content = content.replace(/\\[a-zA-Z]+\d*/g, '')

        // Remove curly braces
        content = content.replace(/\{|\}/g, '')

        // Strip any leftover RTF specific content
        content = content.replace(/\\[a-zA-Z0-9]+/g, '')

        // Add content to HTML
        html += content + '</div>'

        // Clean up any nested spans and multiple breaks
        html = html.replace(/<\/span><\/span><\/span>/g, '</span>')
        html = html.replace(/<br><br>/g, '<br>')

        // Clean up nested bold tags
        html = html.replace(/<strong><strong>/g, '<strong>')
        html = html.replace(/<\/strong><\/strong>/g, '</strong>')

        // Balance any unclosed strong tags
        const strongCount = (html.match(/<strong>/g) || []).length
        const strongCloseCount = (html.match(/<\/strong>/g) || []).length
        if (strongCount > strongCloseCount) {
            html += '</strong>'.repeat(strongCount - strongCloseCount)
        }

        return html
    } catch (error) {
        console.error('RTF parsing error:', error)
        return '<div>Error parsing document</div>'
    }
}

const CcdaHelper = ({ docid, buttonProps, contentType }) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [documentDetails, setDocumentDetails] = useState(null)
    const filePath = getFilePath(docid, contentType)
    const getDetails = async () => {
        try {
            if (contentType === 'text/html') {
                const response = await axiosInstance.get(filePath)
                const tempDiv = document.createElement('div')
                tempDiv.innerHTML = response.data
                const textContent = tempDiv.textContent || tempDiv.innerText
                setDocumentDetails({ html: textContent })
                setLoading(false)
            } else if (contentType === 'text/rtf') {
                try {
                    const response = await axiosInstance.get(filePath, { responseType: 'text' })
                    let rtfContent = response.data

                    // Fix double-quoted content issue
                    if (rtfContent.startsWith('"') && rtfContent.endsWith('"')) {
                        rtfContent = rtfContent.slice(1, -1)
                    }

                    // Convert to HTML
                    const htmlContent = parseRtfToHtml(rtfContent)
                    console.log('Parsed HTML Content:', htmlContent) // Debugging log
                    setDocumentDetails({ html: htmlContent })
                } catch (error) {
                    console.error('Failed to parse RTF:', error)
                    setDocumentDetails({ html: '<p>Error loading document.</p>' })
                } finally {
                    setLoading(false)
                }
            } else if (contentType === 'application/octet-stream') {
                const response = await axiosInstance.get(filePath)
                const textValue = atob(response.data).replace(/\n/g, '<br />')
                setDocumentDetails({ html: textValue })
                setLoading(false)
            } else if (contentType === 'text/plain') {
                const newUrl = `${process.env.REACT_APP_MRC_BASE_URL}/MergeEHR/get-pdf/${docid}`
                const response = await axiosInstance.get(newUrl)
                const textValue = atob(response.data).replace(/\n/g, '<br />')
                setDocumentDetails({ html: textValue })
                setLoading(false)
            } else {
                const newUrl = `${process.env.REACT_APP_MRC_BASE_URL}/MergeEHR/get-pdf/${docid}`
                const response = await axiosInstance.get(newUrl)
                const byteCharacters = atob(response.data)
                const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i))
                const byteArray = new Uint8Array(byteNumbers)
                const blob = new Blob([byteArray], { type: 'application/pdf' })
                const url = URL.createObjectURL(blob)
                setDocumentDetails({ pdf: url })
                setLoading(false)
            }
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    }

    const handleClick = () => {
        setOpen(true)
        if (
            contentType === 'text/html' ||
            contentType === 'application/pdf' ||
            contentType === 'text/plain' ||
            contentType === 'text/rtf' ||
            contentType === 'application/octet-stream'
        ) {
            setLoading(true)
            getDetails()
        }
    }

    const handleClose = () => setOpen(false)

    const renderModal = useCallback(() => {
        if (loading)
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <ReactLoading width={20} height={20} type="spokes" color="rgb(64 60 60)" />
                </div>
            )
        if (documentDetails?.html)
            return (
                <div
                    dangerouslySetInnerHTML={{ __html: documentDetails?.html }}
                    style={{ padding: '20px', whiteSpace: 'normal' }}
                />
            )
        if (documentDetails?.pdf)
            return (
                <iframe
                    id="dlRetpdfPreview"
                    width="100%"
                    height="100%"
                    title="ccda"
                    src={documentDetails?.pdf ? documentDetails?.pdf : ''}
                />
            )

        return <iframe className="iframe-size" src={filePath} title="ccda" />
    }, [documentDetails?.html, documentDetails?.pdf, filePath, loading])

    return (
        <div>
            <MrcButton startIcon={<VisibilityOutlinedIcon />} onClick={handleClick} {...buttonProps}>
                View document
            </MrcButton>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal-size">{renderModal()}</div>
            </Modal>
        </div>
    )
}

export default CcdaHelper
